// Import dependencies
import React from "react";
import {Button, Col, Row} from "antd";
import ReactResizeDetector from "react-resize-detector";
// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import {PageContainer, ParagraphText, TitleText,} from "../styled-components/UILibrary";
import Accordion from "../components/accordion/earnMoreAccordion";
import StepsIndicator from "../components/checkout/stepsIndicator";
import CartItem from "../components/cart/cartItem";
import Step4Form from "../components/checkout/step4Form";
import GeneralCartItem from "../components/cart/genCartItem";
import CheckoutCart from "../components/checkout/checkout_cart";
import InputField from "../components/form/input/inputField";
import GeneralButton from "../components/form/buttons/GeneralButton";
import StatisticsPopup from "../components/form/forms/statisticsPopup";
import Logo from "../assets/icons/logo_green_login.svg";
import { Link } from "gatsby"

// Import styles
import "./step.css";
import "./recycle.css";
import { Constants } from "../constants/Constants";
// Import redux
import {connect} from "react-redux";

import {getFirebase} from "../api/firebase";
import PickUpItem from "../components/cart/pickUpItem";
import {navigate} from "gatsby-link";
import HeaderSeparator from "../components/separators/headerSeparator";
import {Separator} from "../components/separators/separator";
import {getCartQuote, getCartWeight, getCartCoin} from "../util/cart";
import { generateOrderId } from "../util/orderGenerator";
import SectionSeparator from "../components/separators/sectionSeparator";
import Arrow from "../assets/icons/left-arrow-blue.svg";

import { confirmationLoaded } from "../redux/actions/confirmationAction"


/* Step 4 of the checkout process page */
class Step4 extends React.Component {
  constructor(props) {
    super(props);

    this.changeDesire = this.changeDesire.bind(this);
    this.onResize = this.onResize.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.updatePromoCode = this.updatePromoCode.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.isBrowser = this.isBrowser.bind(this);

    const {
      location: { state },
    } = this.props;
    const {codePromoPrev, collectionFee, whenCheckout, creditPreference, addonsArray } = state ? state : {};
    this.state = {
      wantsToJoin: false,
      currentWidth: 0,
      isMember: false,
      popupOpen: false,
      refealCodesArray: [],
      codePromoInput: "",
      codePromo: codePromoPrev ? codePromoPrev : {code: "", value: 0},
      collectionFee: collectionFee,
      order_id: generateOrderId(),
      email: this.props.checkout.account_info && this.props.checkout.account_info.email ? this.props.checkout.account_info.email : "",
      firstName: this.props.checkout.account_info &&  this.props.checkout.account_info.first_name ? this.props.checkout.account_info.first_name : "",
      lastName: this.props.checkout.account_info &&  this.props.checkout.account_info.last_name ? this.props.checkout.account_info.last_name : "",
      isMember: this.props.checkout.membership_info &&  this.props.checkout.membership_info.is_member ? this.props.checkout.membership_info.is_member : "",
      phone: this.props.checkout.account_info &&  this.props.checkout.account_info.phone ? this.props.checkout.account_info.phone : "",
      whenCheckout: whenCheckout,
      creditPreference: creditPreference,
      addonsArray: addonsArray
    };
  }

  isBrowser = () => typeof window !== "undefined";

  componentWillMount() {
    if (this.isBrowser() && !this.props.loggedIn) {
      navigate("/login");
    }
  }

  togglePopup = (content) => {
    this.setState({
      popupOpen: !this.state.popupOpen,
      popupContent: content
    });
  }
  updatePromoCode = (e) => {
    this.setState({ codePromoInput: e.target.value });
  }

  applyPromoCode = () => {
    if (this.state.refealCodesArray.includes(this.state.codePromoInput)) {
      this.setState({ codePromo: {code: this.state.codePromoInput, value: 5}});
    } else {
      this.setState({ codePromo: {code: "", value: 0}});
    }
  }
  changeDesire = desire => {
    this.setState({ wantsToJoin: desire });
  };

  onResize = width => {
    this.setState({ currentWidth: width });
  };

  // Sends data from this form to redux
  recordOrderDetails = async () => {
    let date = new Date();
    const dd = date.getDate();
    const mm = date.getMonth();
    const arrayMonths = [ "January", "February", "March", "April", "May", "June",
     "July", "August", "September", "October", "November", "December"];
     const arrayMonthsShort = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
     "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const yyyy = date.getFullYear();
    let today =  dd + ' ' + arrayMonths[mm] + ' ' + yyyy;
    let todayShort =  dd + '/' + arrayMonthsShort[mm] + '/' + yyyy;

    const duePlusDate = this.state.isMember ? 1 : 3;
    const dueDate = new Date(yyyy, mm, dd+duePlusDate);
    const ddDueteDate = dueDate.getDate();
    const mmDueteDate = dueDate.getMonth();
    const yyyyDueteDate = dueDate.getFullYear();
    const dueDateShort =  ddDueteDate + '/' + arrayMonthsShort[mmDueteDate] + '/' + yyyyDueteDate;

    var hoursReal = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hoursReal >= 12 ? 'PM' : 'AM';
    var hours = hoursReal % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let now = hours + ':' + minutes + ' ' + ampm;

    let challengesDoneId = [];
    this.props.challenges
    .filter(challenge => this.props.userToken ? !challenge.users.includes(this.props.userToken.uid) : challenge)
    .map(challenge => {
      if (challenge.brand == "reinin") {
        if (challenge.category == "none") {
          const itemsLength = this.props.cart.length + this.props.generalCart.reduce( function(a, b){
              return a + (b.isBulk ? b.functionalQuantity : b.quantity);
          }, 0);
          if (itemsLength >= challenge.quantity) {
            challengesDoneId= [...challengesDoneId, challenge.id];
          }
        } else {
          const cartFiler = this.props.cart.filter(item => item.category == challenge.category);
          const generalCartFiler = this.props.generalCart.filter(item => item.category == challenge.category);
          const itemsLength = cartFiler.length + generalCartFiler.reduce( function(a, b){
            return a + (b.isBulk ? b.functionalQuantity : b.quantity);
          }, 0);
          if (itemsLength >= challenge.quantity) {
            challengesDoneId= [...challengesDoneId, challenge.id];
          }
        }
      } else {
        const cartFiler = this.props.cart.filter(item => item.item.startsWith(challenge.brand));
        const generalCartFiler = this.props.generalCart.filter(item => item.item.startsWith("OTHER " + challenge.brand));
        const itemsLength = cartFiler.length + generalCartFiler.reduce( function(a, b){
          return a + (b.isBulk ? b.functionalQuantity : b.quantity);
        }, 0);
        if (itemsLength >= challenge.quantity) {
          challengesDoneId= [...challengesDoneId, challenge.id];
        }
      }
    });
    let listChallenges = [];
    listChallenges = this.props.challenges.filter(challenge => challengesDoneId.includes(challenge.id))

    if (this.props.cart.length > 0 || this.props.generalCart.length > 0) {
      this.props.dispatch(confirmationLoaded({
        pushCartObj: {
          challengeObj: {
            challengesDoneId: challengesDoneId,
            userToken: this.props.userToken.uid
          },
          orderDoc : {
            items: this.props.cart.length > 0 ? this.props.cart.reduce((a, v, i) => ({ ...a, [i]: v}), {}) : null,
            generalItems:
              this.props.generalCart.length > 0 ? this.props.generalCart.reduce((a, v, i) => ({ ...a, [i]: v}), {})  : null,
            userToken: this.props.userToken.uid,
            collection_fee: this.props.checkout.order_info.collection_fee,

            subTotal: (Math.round( (getCartQuote(this.props.cart, this.props.generalCart) ) * 100) / 100),
            promoCode: this.state.codePromo.value ? true : false,
            code_promo_value: this.state.codePromo.value ? this.state.codePromo.value : 0,
            collection_fee: this.props.checkout.order_info.collection_fee,
            order_total: (Math.round(
              ((getCartQuote(this.props.cart, this.props.generalCart) ))
            * 100) / 100) + this.state.codePromo.value,
            refealTo: this.state.codePromo.code,
            totalWeight: getCartWeight(this.props.cart, this.props.generalCart),
            totalItems: this.props.cart.length + this.props.generalCart.reduce( function(a, b){
              return a + (b.isBulk ? b.functionalQuantity : b.quantity);
            }, 0),
            date: todayShort,
            dateFull: today,
            month: mm+1,
            dueDate: dueDateShort,
            dateCode: yyyy + " " + mm + " " + dd + " " + hoursReal + " " + minutes,
            order_id: this.state.order_id,
            "is_member": this.state.isMember,
            "paid_status": false,
            "listChallenges": listChallenges.reduce((a, v, i) => ({ ...a, [i]: v}), {}),
            "totalCoins": getCartCoin(this.props.generalCart) + this.props.challenges.filter(challenge => challengesDoneId.includes(challenge.id)).map(challenge => challenge.reward).reduce((a, b) => a + b, 0),
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "email": this.state.email
          },
          emailDoc: {
            "order_number": this.state.order_id,
            "order_date": today,
            "first_name": this.state.firstName,
            "email": this.state.email,
            "collection_date": this.props.checkout.order_info.collection_details.collection_date,
            "vehicle_type": this.props.checkout.order_info.collection_vehicle,
            "collection_time": this.props.checkout.order_info.collection_details.collection_time,
            "collection_address": this.props.checkout.order_info.collection_details.address,
            "postal_code": this.props.checkout.order_info.collection_details.postal_code,
            "country": "Singapore",
            
            "collection_fee": this.props.checkout.order_info.collection_fee,
            "order_total": (Math.round(
              ((getCartQuote(this.props.cart, this.props.generalCart) ))
            * 100) / 100) + this.state.codePromo.value,
            "order_coins": getCartCoin(this.props.generalCart) + this.props.challenges
            .filter(challenge => challengesDoneId.includes(challenge.id))
            .map(challenge => challenge.reward).reduce((a, b) => a + b, 0),
            "credit_preference": this.props.checkout.order_info.credit_preference,
            "order_items": [...this.props.cart.map(item => {
              let nItem = {};
              nItem["item"] = item.productName;
              nItem["item_number"] = item.productId;
              nItem["condition"] = item.condition;
              nItem["powersOn"] = item.powersOn;
              nItem["quote"] = item.type == "member" || item.isBulk ? "$" + item.quote  : item.quote + " Reinin Coins";
              return nItem;
            }), ...this.props.generalCart.map(item => {
              let nItem = {};
              nItem["item"] = item.item;
              nItem["item_number"] = item.productId;
              nItem["quantity"] = item.quantity;
              nItem["functional_quantity"] = item.isBulk ? item.functionalQuantity : item.quantity;
              nItem["isBulk"] = item.isBulk;
              nItem["quote"] = item.type == "member" || item.isBulk ? "$" + item.total_price  : item.total_price + " Reinin Coins";
              return nItem;
            })],
            "promo_code": this.state.codePromo.value ? this.state.codePromo.value : 0,
            "date": today,
            "time": now,
            "user_name": this.state.firstName + " " + this.state.lastName,
            "user_email": this.state.email,
            "user_phone": this.state.phone,
            "pickup_fee": this.props.checkout.order_info.collection_fee,
            "quote_before_fee" : Math.round(getCartQuote(this.props.cart, this.props.generalCart)* 100) / 100,
            "quote_after_fee": Math.round(((Math.round(getCartQuote(this.props.cart, this.props.generalCart)* 100) / 100) - this.props.checkout.order_info.collection_fee)* 100) / 100,
            "promo_code_value": this.state.codePromo.value ? this.state.codePromo.value : 0,
            "payment_preference": this.props.checkout.order_info.credit_preference,
            "total_quote": (Math.round(
              ((getCartQuote(this.props.cart, this.props.generalCart) ) )
            * 100) / 100) + this.state.codePromo.value,
          }          
        }
      }));

    }

  };

  componentDidMount() {
//    if (getCartQuote(this.props.cart, this.props.generalCart) < 15) {
  if (!(this.props.cart.length + this.props.generalCart.length)) {
    navigate("/");
  }
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      Promise.all([app, firestore]).then(([firebase]) => {
        if (this.props.userToken) {
          getFirebase(firebase)
            .firestore()
            .collection("users")
            .doc(this.props.userToken.uid)
            .onSnapshot(doc => {
              // Get data and store in state
              let data = doc.data();
              if (!data) return;
              this.setState({
                isMember: data.membership_info.is_member,
              });
            });
        }
        getFirebase(firebase)
          .firestore().collection("refeal_codes").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.state.refealCodesArray.push(doc.data().code);
            });
        });
      });
  }

  render() {
    const chevronRight = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path></svg>;
    const {
      location: { state },
      cart,
      generalCart,
      userToken,
      checkout: {
        order_info: {
          collection_method = "",
          collection_vehicle = "",
          collection_fee = 0,
          collection_details: {
            collection_date = "",
            collection_time = "",
            address = "",
            postal_code = "",
          } = {},
          credit_preference = "",

        } = {},
      } = {},
    } = this.props;
    const { isMember } = this.state;
    const wePickUpMethod = collection_method === "We pick up";
    const collectionFee = collection_fee;
    const totalPrice = getCartQuote(cart, generalCart) - collectionFee;
    // const blockedDone = !isMember && totalPrice < parseInt(this.props.configs["minimum_checkout"]);
    const totalCount = cart.length + generalCart.length;
    const blockedDone = totalCount < 1;
//    const blockedDone = totalPrice < 0.0;

    return (


      <Layout noFooter noHeader stepPage step4Page checkoutWhite noWhatsapp>
        <SEO title="Checkout" />
        <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content={this.state.popupContent} />

          <div className="checkout_page_container checkout_page_container_step4" style={this.state.mobileShow ? {height: "100%"} : {}}>
            {/* Process indicator */}
            <div className={!this.state.mobileShow ? "checkout_page_forms" : "checkout_page_forms checkout_page_forms_hide"}>
            <Link to="/" className="checkout_logo" >
                <Logo />
              </Link>
              <span className="step_indicator">Contact{chevronRight}Schedule{chevronRight}Add-ons{chevronRight}<b>Review</b></span>
              <div className="checkout_next_step">
                <div className="step_tittle">Contact</div>
                <span className="active" onClick={() => navigate("/step-1", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
              </div>
              <div className="checkout_next_step">
                <div className="step_tittle">Payment</div>
                <span className="active" onClick={() => navigate("/step-2", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
              </div>
              <div className="checkout_next_step">
                <div className="step_tittle">Time slot</div>
                <span className="active" onClick={() => navigate("/step-3", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
              </div>
              <div className="checkout_step4_mobile">
                <h1>Order review</h1>
                <div className="checkout_overview_mobile">
                  <h1>Pickup details</h1>
                  <span onClick={() => navigate("/step-2", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
                  <PickUpItem
                    collectionMethod={collection_method}
                    address={address}
                    date={collection_date}
                    time={collection_time}
                    postalCode={postal_code}
                    collectionVehicle={collection_vehicle}
                    collectionFee={collection_fee}
                    addonsFee={
                      this.state.addonsArray && this.state.addonsArray.map(addon => addon.price)
                      .reduce((total, addonPrice) => total + addonPrice, 0)
                    }
                  />
                </div>
                <div className="checkout_overview_mobile">
                  <h1>How we’ll pay you</h1>
                  <span onClick={() => navigate("/step-3", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
                  <PickUpItem
                    creditPreference
                    credit_preference={this.state.creditPreference}
                  />
                </div>
                <div className="checkout_overview_mobile promo_code_btn_mobile">
                  <h1>Promo code</h1>
                    <InputField
                      placeholder="Promo Code"
                      noForm
                      id="code_promo"
                      type="string"
                    />
                    <GeneralButton
                      title="APPLY"
                      type="primary"
                    />
                </div>
              </div>
            </div>
            
            <div className="checkout_cart_overview_page">
              
              <CheckoutCart
              codePromo={this.state.codePromo}
              updatePromoCode={this.updatePromoCode}
              applyPromoCode={this.applyPromoCode}
              togglePopup={this.togglePopup}
              overview
              mobileShow={this.state.mobileShow}
              onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
              collectionFee={state && state.collectionFee}
              collectionInfo={this.props.collectionVehicles[this.props.checkout.order_info?.collection_vehicle]}
              isMember={this.state.isMember}
              addonsArray={this.state.addonsArray}
              >
                <Step4Form
                  collectionInfo={this.props.collectionVehicles[this.props.checkout.order_info?.collection_vehicle]}
                  blockedDone={blockedDone}
                  wantsToJoin={this.state.wantsToJoin}
                  recordOrderDetails={this.recordOrderDetails}
                  isMember={this.state.isMember}
                  checkoutPreview
                  orderId={this.state.order_id}
                  email={this.state.email}
                  firstName={this.state.firstName}
                  addonsArray={this.state.addonsArray}
                />
              </CheckoutCart>
            </div>

          </div>
      </Layout>


    );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  collectionVehicles: state.product.collectionVehicles,
  cart: state.cart,
  generalCart: state.generalCart,
  userToken: state.user.userToken,
  checkout: state.checkout,
  configs: state.product.configs,
  challenges: state.challenges.challenges,  
  loggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps)(Step4);
